#navbar { 
  margin: 0px;
  width: 20%;
}

.jump-header {
  padding-top: 0px;
  text-align: center;
}

.nav-button {
  margin: 2px;
  height: 4em;
  display: block;
  width: 100%;
  font-weight: bold;
  font-size: 1em;
  text-decoration: none;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
  border-radius: 5px;
  background-color: #ddd;
  opacity: 0.75;
  transition: 0.3s;
}

.nav-button:hover,
.nav-button:focus,
.nav-button:active {
  border-color: #7D8AFF;
  opacity: 1;
}

.nav-button-a {
  text-decoration: none;
}