.tall_image_modal { 
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 10vh;
  height: 80vh;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.modal-image {
  height: 80vh;
}

.image-modal-close {
  position: absolute;
  font-weight: bolder;
  font-size: 1em;
  top: 0px;
  right: 0px;
  height: 2em;
  width: 2em;
  border-style: solid;
  border-width: 1px;
  border-color: #999;
  border-radius: 2px;
  cursor: pointer;
  
}