.letter { 
  width: 79%;
  margin-top: 10px;
  margin-left: 10px;
  padding: 5px;
  background-color: #eee;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
}

.letter-title {
  margin-top: 10px;
  padding-bottom: 8px;
  border-style: solid;
  border-width: 0px;
  border-color: #ccc;
  border-bottom-width: 1px;
}

.letter-title small {
  padding-left: 10px;
  font-size: 0.75em;
  color: #888;
}

.letter-body {
  padding-top: 10px;
}

.result-header {
  
  margin-left: 0px;
  margin-right: 0px;
  padding: 10px;
  padding-bottom: 0px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
}

.result-header-success {
  background-color: #A3FFD1;
  border-color: #338052;
}

.result-header-failure {
  background-color: #FFB963;
  border-color: #FF9517;
}

.response-content { 
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas: "thumbnail letter";
}

.response-image {
  grid-area: "thumbnail";
  width: 250px;
  padding: 3px;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  border-color: #bbb;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.response-html {
  margin-top: 10px;
  grid-area: "letter";
}