#header {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #bbb;
}

#header h1 {
  margin-bottom: 0px;
}

#header h1 small{
  color: #bbb;
  padding-top: 0px;
  margin-top: -0px;
  font-size: 0.5em;
}

.header-span {
  font-size: 1.5em;
  color: #777;
}

.preface {
  font-size: 0.75em;
}